.filter {
    .filter-header {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;

        .header-title {
            #teamsDefault & {
                color: black !important;
            }

            #teamsDark & {
                color: white !important;
            }

            #teamsHighContrast & {
                color: white !important;
            }
        }
    }

    .filter-input-container {
        margin-right: 0 !important;
        max-width: 200px;

        .filter-input {
            height: 0;

            input {
                text-align: right;
                height: 25px;
            }
        }
    }

    .ui-menu__itemwrapper:hover, .ui-menu__itemwrapper:active {
        input {
            #teamsDefault & {
                background-color: white !important;
            }
        }
    }

    .ui-menu__itemindicator {
        margin-right: 13px;
    }

    .ui-checkbox {
        padding: 0 !important;
        margin-bottom: 3px;
    }

    .unique-menu-item-check {
        color: transparent;

        &.checked {
            #teamsDefault & {
                color: #5B5FC7;
            }

            #teamsDark & {
                color: #9399F5;
            }

            #teamsHighContrast & {
                color: yellow;
            }
        }
    }

    .ui-menu__item__submenu {
        max-height: 400px;
        overflow: scroll;
    }
}