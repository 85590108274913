.planning {
    overflow: scroll;

    .planning-groups-container {
        overflow: scroll;
        position: relative;
        padding-bottom: 50px;

        .planning-groups-container-shadow {
            z-index: 1;
            position: sticky;
            margin-top: -50px;
            top: 0;
            min-height: 50px;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.15);
        }
    }

    .planning-today-cell {
        #teamsDefault & {
            background-color: #f3efff !important;
        }

        #teamsDark & {
            background-color: rgb(54, 57, 134) !important;
        }

    }

    .planning-weekend-cell {
        #teamsDefault & {
            background-color: #e5e5e5 !important;
        }

        #teamsDark & {
            background-color: #000000 !important;
        }
    }
}