.request-card-skeleton {
    height: 51px !important;
    border-radius: 4px;
    width: 100% !important;
}

.request-card-btn {
    overflow: hidden;
    height: fit-content !important;
    text-align: left;
    padding: 0 !important;

    .request-card-content {
        padding: 8px 10px;
    }
}