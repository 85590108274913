.request-preview-item {
    height: fit-content;
    width: 100%;

    .request-preview-item-default-avatar {
        border-radius: 100px;
        background-color: #5B5FC7;
        color: white;
        height: 32px;
        width: 32px;
        flex-shrink: 0;
    }

    .request-preview-item-card {
        border-radius: 4px;
        padding: 10px;
        height: fit-content;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0.2rem 0.4rem - 0.075rem;
        gap: 5px;

        #teamsDefault & {
            background-color: white;
            border: 1px solid rgb(225, 223, 221);
        }

        #teamsDark & {
            background-color: rgb(45, 44, 44);
            border: 1px solid rgb(96, 94, 92);
        }
    }
}