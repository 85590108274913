.shift-recurrence {
    border-radius: 4px;
    padding: 5px 8px;
    overflow: hidden;
    gap: 6px;

    #teamsDefault & {
        border: 1px solid #d5d5d5;
    }

    #teamsDark & {
        border: 1px solid rgb(96, 94, 92);
    }

    .shift-recurrence-menu-button {
        margin: 0;
        padding: 0;
        min-width: 0;
        width: fit-content;
        height: 20px;
    }
}

.shift-recurrence-menu-item {
    .ui-menubutton__menu {
        max-height: 250px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}