.planning-shop-part {
    overflow: hidden;

    .planning-shop-part-loader {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        justify-content: center;
        align-items: center;
    }
}