.shift-picker {
    .shift-picker-dropdown {
        width: 100%;

        .ui-dropdown__items-list {
            display: none;
        }

        .ui-dropdown__selected-items div[role="listbox"] {
            width: 100%;
        }

        .ui-dropdown__trigger-button {
            .ui-button__content {
                font-weight: 400;
                color: darkgray;
            }
        }
    }

    &.shift-picker-mobile {
        .ui-datepicker__calendar {
            .ui-datepicker__calendargrid {
                width: 100%;
            }
        }
    }

}