.popup-menu-button {

    & > div {
        background-color: transparent;
        box-shadow: none;
        margin: 0;
        padding: 0;
        width: 100%;
        overflow: visible;
    }

    .ui-menu__itemwrapper {
        border: 0;

        .ui-menu__item {
            border: 2px solid transparent;
            display: flex;
        }
    }

    .ui-menu__divider {
        margin: 3px 0;
    }
}