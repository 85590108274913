@media screen and (max-width: 440px) {
    .date-range-selector-picker {
        .ui-datepicker__calendar {
            .ui-datepicker__calendargrid {
                width: calc(100vw - 22px) !important;
            }
        }
    }
}

.date-range-selector-picker {
    .ui-menu__item {
        padding: 0 3px;
    }

    .ui-menu__itemwrapper {
        background-color: inherit !important;
    }

    .ui-datepicker__calendar {
        min-height: 0;

        .ui-datepicker__calendargrid {
            width: 440px;

            .ui-datepicker__calendarcellbutton {
                border-radius: 0;
            }
        }
    }
}