.requests-view {
    overflow: hidden;

    .requests-list {
        overflow: scroll;
        width: 400px;
        flex-shrink: 0;
        padding: 10px 15px;
        z-index: 1;
        gap: 20px;

        #teamsDefault & {
            box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
        }

        #teamsDark & {
            box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.6);
        }
    }

    &.requests-view-mobile {
        .requests-list {
            width: 100%;
        }
    }

    .request-preview {
        #teamsDefault & {
            background-color: #f6f6f6;
        }

        #teamsDark & {
            background-color: black;
        }
    }
}