.staff-shifts {
    margin: -1px 0 -1px 0;

    .staff-cell {
        min-width: 180px;
        max-width: 250px;
        overflow: hidden;
    }
}

.staff-shift-warning-tooltip {
    & > div {
        background-color: red;
        color: white !important;
    }
}