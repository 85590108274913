$button-size: 100px;
$button-icon-size: 1.8rem;

.clocking {
    .play-button, .stop-button {
        height: $button-size;
        width: $button-size;

        > div {
            height: $button-icon-size;
            width: $button-icon-size;

            svg {
                height: 100%;
                width: 100%;
            }
        }
    }

    .play-button:not(:disabled), .stop-button:not(:disabled) {
        > div svg {
            color: white !important;
        }
    }

    .play-button:not(:disabled) {
        background-color: #5b5fc7 !important;
    }

    .stop-button:not(:disabled) {
        background-color: #c3314a !important;
    }

}