.cell {
    overflow: hidden;

    &.inline {
        padding: 5px;
    }

    &:not(.selected) {
        #teamsDefault & {
            background-color: #F5F5F5;
        }

        #teamsDark & {
            background-color: #1E1E1E;
        }
    }

    &.selected {
        #teamsDefault & {
            background-color: #bcc2ff !important;
        }

        #teamsDark & {
            background-color: #363986 !important;
        }
    }

    &:not(.no-border) {
        #teamsDefault & {
            border: 1px solid #dadada;
        }

        #teamsDark & {
            border: 1px solid rgb(96, 94, 92);
        }
    }

    &:not(.inline):not(.no-padding) {
        padding: 10px 15px;
        margin: 0 -1px -1px 0;
    }

    &.primary:not(.selected) {
        #teamsDefault & {
            background-color: #FFF;
        }

        #teamsDark & {
            background-color: #000;
        }
    }
}