.shop-accordion {

    .shop-accordion-content {
        #teamsDark & {
            background-color: black;
        }
    }

    .hover-shop-accordion-item {
        visibility: hidden;
    }

    &:hover {
        .hover-shop-accordion-item {
            visibility: visible;
        }
    }
}