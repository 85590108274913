.planning-configuration-dialog {
    .planning-configuration-dialog-menu {
        border: 0;
        padding: 0 20px 15px 20px;
        box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
        flex-shrink: 0;
        z-index: 10;
    }

    .planning-configuration-dialog-content {
        overflow: scroll;
        position: relative;

        & > .ui-flex {
            padding-top: 20px;
            padding-bottom: 25px;
        }
    }
}