.shop-categories-table {
    margin-top: -10px;

    .category-color-container {
        flex-shrink: 0;
        overflow: hidden;
        border: 1px solid darkgray;
        border-radius: 100px;
        position: relative;
        height: 16px;
        width: 16px;
    }
}