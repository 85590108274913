$min-height: 60px;
$small-min-height: 40px;

.shift-card-skeleton {
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    min-height: $min-height;

    #teamsDefault & {
        background-color: #e0e0e0;
    }

    #teamsDark & {
        background-color: #333;
    }
}

.shift-card-add-button {
    border: 1px dashed darkgray;
    border-radius: 4px;
    cursor: pointer;
}

.shift-card-container {
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    min-height: $min-height;

    #teamsDefault & {
        background-color: white;
    }

    #teamsDark & {
        background-color: #1e1e1e;
    }

    &.small-shift-card-container {
        min-height: $small-min-height;

        .shift-card-border {
            min-height: calc($small-min-height - 2px);
        }

        .shift-card {
            min-height: $small-min-height !important;
        }
    }

    &.square {
        border-radius: 0 !important;

        .shift-card {
            border-radius: 0 !important;
        }
    }

    .shift-card-border {
        height: 100%;
        width: 5px;
        min-width: 5px;
        flex-shrink: 0;
        margin-right: 3px;
        min-height: calc($min-height - 2px);
    }

    .shift-card {
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        overflow: hidden;
        min-height: $min-height;

        .shift-card-content {
            padding: 2px 4px 4px 4px;
            overflow: hidden;

            .shift-notes {
                margin-top: 2px;
            }
        }

        .shift-card-avatar {
            margin: 0 5px 0 7px;
        }

        .shift-card-shop {
            width: 100%;
            align-items: center;
            gap: 3px;
            padding: 1px 3px;
            color: white;
        }

        &.dragging {
            opacity: 0;
            cursor: grabbing;
        }

        &.drag-preview {
            opacity: 0.5;
        }

        .hover-shift-card-item {
            visibility: hidden;
        }

        &:hover {
            .hover-shift-card-item {
                visibility: visible;
            }
        }
    }
}

.fade-transparent {
    animation: fade-transparent 0.15s ease-out forwards;
}

@keyframes fade-transparent {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.2;
    }
}

@keyframes shift-border-animation {
    0% {
        background-position-y: 0;
    }
    100% {
        background-position-y: -23px;
    }
}

@keyframes shift-border-animation-top {
    0% {
        background-position-y: 0;
    }
    100% {
        background-position-y: -18px;
    }
}