.planning-header {
    overflow: hidden;
    flex-shrink: 0;

    #teamsDefault & {
        border-bottom: 1px solid #e5e5e5;
    }

    #teamsDark & {
        border-bottom: 1px solid rgb(96, 94, 92);
    }

    &.planning-header-with-scrollbar {
        padding-right: 18px;
    }

    & > .ui-flex {
        margin-bottom: -1px;
    }

    .first-cell {
        min-width: 180px;
        max-width: 250px;
        overflow: hidden;
    }
}