$borderRadius: 6px;

.group-shifts-table {

    .group-shifts-table-label {
        padding: 0 !important;
        border: 0 !important;
        overflow: hidden;
    }

    .group-shifts-table-header {
        .table-sort-indicator {
            color: white;
        }
    }

    .monthly-shifts-column-header {
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        background-color: black;
        color: white;
        overflow: hidden;

        .top-part {
            border-bottom: 1px solid rgb(237, 235, 233);
            overflow: hidden;
        }

        .bottom-part {
            overflow: hidden;

            .ui-flex {
                overflow: hidden;
                padding: 0 8px;

                &:first-child {
                    border-right: 1px solid rgb(237, 235, 233);
                }
            }
        }
    }

    .monthly-shifts-column-item {
        color: black;
        background-color: #efefef;
        overflow: hidden;
        padding: 0;

        .monthly-shifts-column-item-parts {
            .ui-flex:first-child {
                border-right: 1px solid rgb(237, 235, 233);
            }
        }
    }
}