.side-view {
    position: fixed;
    top: 0;
    overflow: hidden;

    #teamsDefault & {
        background-color: white;
        box-shadow: -10px 0 20px rgba(0, 0, 0, 0.2);
    }

    #teamsDark & {
        background-color: black;
        box-shadow: -10px 0 20px rgba(0, 0, 0, 0.5);
    }

    &.open-view {
        transition: all 0.4s cubic-bezier(0, 0, 0, 1);
        left: 0;
    }

    &.close-view {
        transition: all 0.25s cubic-bezier(.5, 0, 1, 1);
        left: calc(100% + 50px);
    }

    &.blocked-side-view {
        pointer-events: none;
    }

    .side-view-header {
        z-index: 1;
        padding: 8px 10px;

        #teamsDefault & {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        }

        #teamsDark & {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        }
    }
}