.shop-popup {
    & > div {
        padding: 10px;
    }

    .ui-menu {
        padding: 0;

        .ui-menu__item {
            padding: 6px 8px;
        }
    }

    .unique-menu-item-check {
        color: transparent;

        &.checked {
            #teamsDefault & {
                color: #5B5FC7;
            }

            #teamsDark & {
                color: #9399F5;
            }

            #teamsHighContrast & {
                color: yellow;
            }
        }
    }
}