.group-order-card {
    cursor: move;
    overflow: hidden;
    border-radius: 4px;
    padding: 8px 12px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    gap: 12px;

    #teamsDefault & {
        background-color: white;
        border: 1px solid #d9d9d9;
    }

    #teamsDark & {
        background-color: rgb(41, 41, 41);
        border: 1px solid rgb(96, 94, 92);
    }

    &.group-order-card-highlighted {
        border: 1px solid rgb(91, 95, 199) !important;
        color: rgb(91, 95, 199);
    }
}