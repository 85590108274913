@use "variables";

@media screen and (hover: hover) and (max-width: 1000px) {
    #root {
        zoom: 70%;
        // Fix issue with popups not aligned
        border: 3px solid transparent;

        .ui-popup__content {
            scale: 1.3;
            padding-top: calc(100vh * 0.06);
        }
    }
}

@media screen and (hover: hover) and (min-width: 1000px) and (max-width: 1200px) {
    #root {
        zoom: 80%;

        .ui-popup__content {
            scale: 1.2;
            padding-top: calc(100vh * 0.04);
        }
    }
}

@media screen and (hover: hover) and (min-width: 1200px) and (max-width: 1400px) {
    #root {
        zoom: 90%;

        .ui-popup__content {
            scale: 1.1;
            padding-top: calc(100vh * 0.02);
        }
    }
}

@media screen and (max-width: 750px) {
    *::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .flex-grid {
        gap: 10px !important;
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    }
}

@media screen and (min-width: 750px) {
    .flex-grid {
        gap: 15px !important;
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) !important;
    }
}

html, body, #root, #root > .ui-provider, #root > .ui-provider > div {
    width: 100%;
    height: 100%;
    overscroll-behavior-y: contain;
    overflow: hidden;
}

div,
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
}

.pos-relative {
    position: relative !important;
}

.ui-provider {
    background: transparent !important;
}

.fill-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.fill-width {
    position: absolute;
    left: 0;
    right: 0;
}

.fill-height {
    position: absolute;
    top: 0;
    bottom: 0;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.h-20px {
    height: 20px !important;
}

.hidden {
    display: none !important;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-disabled {
    cursor: not-allowed !important;
}

.ui-dropdown__item {
    cursor: pointer !important;
}

.ui-dialog__overlay {
    &.open {
        animation: fadein 0.2s;
    }

    &.close {
        animation: fadeout 0.2s;
    }
}

.ui-dialog {
    overflow: visible !important;
}

.delete-btn {
    color: #FF0000 !important;
    flex-shrink: 0 !important;
}

.delete-btn-primary {
    background-color: #FF0000 !important;
    flex-shrink: 0 !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-scroll {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}

.p-20px {
    padding: 20px !important;
}

.ui-tooltip__content {
    max-width: 100% !important;
}

.top-padding {
    padding-top: variables.$padding;

    .on-mobile & {
        padding-top: variables.$mobile-padding;
    }
}

.bottom-padding {
    padding-bottom: variables.$padding;

    .on-mobile & {
        padding-bottom: variables.$mobile-padding;
    }
}

.y-padding {
    padding-top: variables.$padding !important;
    padding-bottom: variables.$padding !important;

    .on-mobile & {
        padding-top: variables.$mobile-padding !important;
        padding-bottom: variables.$mobile-padding !important;
    }
}

.x-padding {
    padding-left: variables.$padding !important;
    padding-right: variables.$padding !important;

    &.with-scrollbar {
        padding-right: 5px !important;
    }

    .on-mobile & {
        padding-left: variables.$mobile-padding !important;
        padding-right: variables.$mobile-padding !important;

        &.with-scrollbar {
            padding-right: variables.$mobile-padding !important;
        }
    }
}

.stretch-btn {
    padding: 0 !important;
    min-width: 0 !important;
}

.no-wrap {
    white-space: nowrap !important;
}

.datepicker-input {
    input {
        padding-right: 12px;
    }
}

.ui-dropdown__items-list {
    min-width: fit-content;
}

.ui-dialog__header {
    width: 100%;
}

.custom-icon {
    .ui-button & {
        transform: scale(1.3);
    }

    .ui-menu__item & {
        transform: scale(1.3);
    }
}

.no-shrink {
    flex-shrink: 0 !important;
}

.hide-scrollbar {
    *::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.toolbar {
    width: 100%;
    padding: 10px 15px;
    gap: 15px;
    z-index: 10;

    #teamsDefault & {
        box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
    }

    #teamsDark & {
        box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.8);
    }

    .ui-button {
        min-width: 32px !important;
    }
}

.fade {
    animation: fadein 0.15s ease-out;
}

.color-picker {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 30px;
    height: 32px;
    border: none;
    cursor: pointer;
}

.color-picker::-webkit-color-swatch {
    border-radius: 50%;
    border: 1px solid darkgray;
}

.color-picker::-moz-color-swatch {
    border-radius: 50%;
    border: 1px solid darkgray;
}