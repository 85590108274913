.create-request-dialog {
    .create-request-dialog-menu {
        border: 0;
        padding: 0 20px 15px 20px;
        box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
        flex-shrink: 0;
        z-index: 10;
    }

    .create-request-dialog-content {
        overflow: scroll;

        & > .ui-flex {
            padding: 20px 25px 25px 25px;
        }
    }
}