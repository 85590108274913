.badge-view {
    padding: 50px;
    overflow: hidden;

    .badge-qr-code {
        height: 100%;
        width: 100%;
        max-width: 500px;
        max-height: 500px;
    }
}