.errors-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    padding: 15px;
    align-items: end;
    justify-content: end;
}