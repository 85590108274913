.planning-date-range {
    margin-right: -150px;
}

.planning-view-menu-button {
    .ui-menu__itemcontent {
        margin: 0;
        width: 100%;
    }

    .planning-view-button-check {
        #teamsDefault & {
            color: rgb(91, 95, 199);
        }

        #teamsDark & {
            color: rgb(91, 95, 199);
        }
    }
}