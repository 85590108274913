.accordion {
    overflow: hidden;
    margin-bottom: -1px;
    width: 100%;

    > .accordion-header {
        padding: 5px 15px;
        gap: 15px;
        overflow: hidden;
        flex-shrink: 0;
    }

    > .accordion-content-container {
        transition: 0.3s height ease;
        overflow: hidden;
        position: relative;
        flex-shrink: 0;

        .accordion-content {
            flex-shrink: 0;
        }
    }

    &.accordion-card {
        border-radius: 6px;

        #teamsDefault & {
            background-color: white;
            border: 1px solid #d9d9d9;
            box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
        }

        #teamsDark & {
            background-color: #2D2C2C;
            border: 1px solid rgb(96, 94, 92);
            box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.8);
        }

        > .accordion-header {
            border-radius: 6px;
            margin-left: -8px;
            cursor: pointer;
        }

        > .accordion-header-not-expandable {
            margin: 5px 0;
            cursor: default;
        }
    }

    &:not(.accordion-card) {
        > .accordion-header {
            border-top-width: 2px !important;

            #teamsDefault & {
                border: 1px solid #e5e5e5;
            }

            #teamsDark & {
                border: 1px solid rgb(96, 94, 92);
            }

            cursor: pointer;
        }

        > .accordion-content-container {
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                pointer-events: none;

                #teamsDefault & {
                    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.15);
                }

                #teamsDark & {
                    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.8);
                }
            }
        }
    }
}