.badge-error-dialog {
    .badge-error-clocking-icon {
        border-radius: 100px;
        height: 16px;
        width: 16px;
        align-items: center;
        justify-content: center;

        svg {
            height: 8px;
            width: 8px;
        }
    }
}