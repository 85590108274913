$button-size: 70px;
$button-icon-size: 1.5rem;

.mobile-add-button {
    position: absolute !important;
    bottom: 15px;
    right: 15px;
    height: $button-size !important;
    width: $button-size !important;
    z-index: 1;

    > div {
        height: $button-icon-size;
        width: $button-icon-size;

        svg {
            height: 100%;
            width: 100%;
            color: white !important;
        }
    }

    background-color: #5b5fc7 !important;
}