@use "variables";

.kpi {
    overflow: visible;
    text-overflow: ellipsis;
    width: 100%;
    min-height: 300px;
    max-height: 300px;
    padding: 0.6rem 1rem 1rem 1rem;
    border-radius: 6px;

    #teamsDefault & {
        background-color: white;
        box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid #d9d9d9;
    }

    #teamsDark & {
        background-color: inherit;
        box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.8);
        border: 1px solid rgb(96, 94, 92);
    }
}

.kpis-grid {
    gap: 15px !important;
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    grid-auto-flow: dense;
}

.small-kpis-grid {
    gap: 15px !important;
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    grid-auto-flow: dense;
}