.people-button {
    padding: 0 10px !important;

    .text-skeleton > span {
        width: 150px;
    }

    &.people-button-fluid {
        .text-skeleton > span {
            width: 999px;
        }
    }

    &.people-button-danger {
        background-color: red !important;
        color: white !important;
    }
}