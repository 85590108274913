.shops-groups-reports {
    padding: 15px;

    #teamsDefault & {
        background-color: #F6F6F6;
    }

    #teamsDark & {
        background-color: #181818;
    }
}