.group-accordion {

    .hover-group-accordion-item {
        visibility: hidden;
    }

    &:hover {
        .hover-group-accordion-item {
            visibility: visible;
        }
    }
}